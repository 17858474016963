import React from "react";
import styled from "styled-components";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
// ui
import { SliderRail, Handle, Track, Tick } from "./assets/slider-assets"; // example render components - source below
import { isUndefined } from "util";

const sliderStyle = {
  position: "relative",
  width: "100%",
};

const SliderControl = ({
  label,
  displayValue,
  value,
  values,
  onChange,
  disabled,
  maxWidth = 500,
  showTicks = false,
  min = 0,
  max = 1,
  step = 0.001,
}) => {
  const domain = [min, max];

  if (isUndefined(value) && isUndefined(values)) return <pre>null value</pre>;

  // if only one value given, turn into array so can treat the same
  if (!values) values = [value];

  // only return array if there are multiple values
  const onSliderUpdate = (values) => {
    let newValue = values.length > 1 ? values : values[0];
    const hasDecimals = newValue % 1 > 0;

    onChange(hasDecimals < 1 ? newValue : newValue.toFixed(1));
  };

  return (
    <Container>
      <div style={{ maxWidth: maxWidth }}>
        <SliderLabel disabled={disabled}>
          {label}: <span>{displayValue && values}</span>
        </SliderLabel>
        <Slider
          disabled={disabled}
          mode={2}
          step={step}
          domain={domain}
          rootStyle={sliderStyle}
          onUpdate={onSliderUpdate}
          values={values}
        >
          <Rail>
            {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map((handle) => (
                  <Handle
                    disabled={disabled}
                    key={handle.id}
                    handle={handle}
                    domain={domain}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>

          {showTicks && (
            <Ticks count={5}>
              {({ ticks }) => (
                <div className="slider-ticks">
                  {ticks.map((tick) => (
                    <Tick key={tick.id} tick={tick} count={ticks.length} />
                  ))}
                </div>
              )}
            </Ticks>
          )}
        </Slider>
      </div>
    </Container>
  );
};

export default SliderControl;

const Container = styled.div`
  height: 42px;
  padding-left: 7px;
  padding-right: 7px;
`;

const SliderLabel = styled.div`
  width: 100%;
  text-transform: uppercase;
  font-size: 12px;
  min-height: 30px;
  color: rgba(0, 0, 0, 0.7);

  span {
    color: rgba(0, 0, 0, 0.7);
  }
`;
